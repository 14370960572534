import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-automate-icon',
    standalone: true,
    imports: [CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `<svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" fill="none" />
        <path
            d="M6.73773 12.3861H8.27159V17.66H10.0122V18.9794H13.248V17.66H14.9422V19.9531H17.2623V21.2725H20.5V18.0348H17.2623V19.1803H15.7149V15.3399H17.2623V16.4835H20.5V13.2477H17.2623V14.5672H14.9422V16.8873H13.248V15.7436H10.0122V16.8873H9.04432V7.11228H10.0122V8.25785H13.248V7.11228H14.9422V9.43239H17.2623V10.7518H20.5V7.51603H17.2623V8.65966H15.7149V4.81921H17.2623V5.96478H20.5V2.72705H17.2623V4.04648H14.9422V6.33955H13.248V5.02012H10.0122V6.33955H8.27159V11.6134H6.73773V10.3809H3.5V13.6186H6.73773V12.3861Z"
            fill="currentColor" />
    </svg> `,
})
export class AutomateIconComponent {}
